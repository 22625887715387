@import 'src/assets/scss/styles';

.singleInput {
  border: 1px solid #c1c1c1;
  display: flex;

  margin-bottom: 15px;
}
.singleInput i {
  padding: 8px 16px;
  color: white;
}
.socialLogin {
  width: 330px;
  border: 1px solid #c1c1c1;
  display: flex;
  height: 41px;
  border-color: transparent;
}
.singleInput input {
  border: 0px solid #c1c1c1;
  width: 100%;
  outline: none;
  height: 30px;
  font-size: 18px;
}
::placeholder {
  color: white;
  opacity: 1;
}

.margins {
  margin: 10px;
  color: white;

  background-color: transparent;
}

.btn {
  background-color: transparent;
  color: white;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #c1c1c1;
  height: 45px;
  width: 100%;
}
.mainWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .contentWrap {
    max-width: 500px;
  }

  .inputsWrap {
    max-width: 500px;
  }
}
.singleButton {
  border: 2px solid #c1c1c1;
  display: flex;
  justify-content: center;
  height: 45px;
}
