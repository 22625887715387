@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
$breakpoint-height-sidebar: 600px;
$breakpoint-height: 900px;
$breakpoint-tablet: 768px;
$breakpoint-mobile: 480px;
$breakpoint-large: 1480px;
$website-height: 100%;
$side-bar-width: 150px;
$side-bar-width-collapsed: 130px;
$dark-purple: #562466;
$light-purple: #934599;
$sidebar-grad: #562466, #934599;
$side-nav-link-padding-y: 6rem;
$side-nav-link-padding-x: 15px;
$full-width: 100%;
$display-flex: flex;
$white: #ffffff;
$text-decoration: none;

@mixin gradient($direction, $list) {
  background: -webkit-linear-gradient($direction, $list);
  background: -moz-linear-gradient($direction, $list);
  background: -o-linear-gradient($direction, $list);
  background: linear-gradient($direction, $list);
}

.sidebar {
  @include position(fixed, $top: 0, $left: 0);
  height: $website-height;
  min-width: $side-bar-width;
  @include gradient(45deg, $sidebar-grad);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 77;
  flex-direction: column;

  &.collapsed {
    min-width: $side-bar-width-collapsed !important;
  }
}

.sidebar {
  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;

    li {
      text-align: center;
      margin-block: 10px;
      img {
        max-width: 33px;
      }
      a {
        color: $white !important;
        text-decoration: $text-decoration;
      }

      a i {
        display: block;
        background: #fff;
        width: 55px;
        height: 55px;
        line-height: 55px;
        text-align: center;
        border-radius: 50%;
        font-size: 22px;
        color: #77357d;
        margin: auto;
      }

      a span {
        font-size: 16px;
        display: block;
      }

      .logoutBtn {
        color: $white !important;
        text-decoration: $text-decoration;
        border: none;
        background: transparent;
      }

      .logoutBtn i {
        display: block;
        background: #fff;
        width: 55px;
        height: 55px;
        line-height: 55px;
        text-align: center;
        border-radius: 50%;
        font-size: 22px;
        color: #77357d;
        margin: auto;
      }

      .logoutBtn span {
        font-size: 16px;
        display: block;
      }
    }
  }
}
.innerlogod {
  display: block;
  @include position(relative, $top: 0px, $left: initial);
  margin-bottom: 20px;
}
.pushbutton {
  @include position(absolute, $right: -20px);
}

.pushbutton {
  button {
    background: white;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    line-height: 50px;
    text-align: center;
    display: block;
    border: none;

    i {
      color: #77357d;
      font-size: 20px;
    }
  }
}
.sidebar {
  &.collapsed {
    .pushbutton {
      right: -30px;
    }
  }
}
@media (min-width: $breakpoint-large) and (min-height: $breakpoint-height) {
  .sidebar {
    li {
      margin-block: 15px !important;

      img {
        max-width: 60px !important;
      }
    }
  }
}
@media (min-width: $breakpoint-large) {
  .sidebar {
    min-width: 180px;
  }
}
@media (max-width: $breakpoint-tablet) {
  .pushbutton {
    @include position(absolute, $top: -30px);
    right: initial;
    display: none;
  }

  .innerlogod {
    display: none;
  }

  .sidebar {
    @include position(fixed, $bottom: 0, $left: 0, $right: 0);
    width: $full-width;
    height: initial;
    top: auto;
  }

  .sidebar {
    ul {
      li {
        display: inline-block;
        margin-top: 15px;
        margin-inline: 10px;

        a i {
          width: 50px;
          height: 50px;
          line-height: 50px;
          font-size: 20px;
        }

        a span,
        .logoutBtn span {
          display: none;
        }
      }
    }
  }
}
@media (max-height: $breakpoint-height-sidebar) {
  .pushbutton {
    @include position(absolute, $top: -30px);
    right: initial;
    display: none;
  }

  .innerlogod {
    display: none;
  }

  .sidebar {
    @include position(fixed, $bottom: 0, $left: 0, $right: 0);
    width: $full-width;
    height: initial;
    top: auto;
  }

  .sidebar {
    ul {
      li {
        display: inline-block;
        margin-top: 15px;
        margin-inline: 10px;

        a i {
          width: 50px;
          height: 50px;
          line-height: 50px;
          font-size: 20px;
        }

        a span,
        .logoutBtn span {
          display: none;
        }
      }
    }
  }
}
@media (max-width: $breakpoint-mobile) {
  ul {
    li {
      img {
        max-width: 30px !important;
      }
    }
  }
}
