$white: #fff;
$navbar-nav-link-padding-x: 2rem;
$navbar-dark-color: $white;
$navbar-nav-link-font-size: 21px;
$navbar-nav-link-padding-y: 1.6rem;
$breakpoint-mini: 992px;
$breakpoint-tablet: 768px;

.navigation {
  .navbar-nav {
    .nav-link {
      padding-left: $navbar-nav-link-padding-x;
      padding-right: $navbar-nav-link-padding-x;
      color: $navbar-dark-color;
      font-size: $navbar-nav-link-font-size;
      padding-bottom: $navbar-nav-link-padding-y;
      padding-top: $navbar-nav-link-padding-y;
    }
  }
}
@media (max-width: $breakpoint-mini) {
  .navigation {
    .navbar-nav {
      .nav-link {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .navigation {
    .navbar-nav {
      .nav-link {
        padding-block: 1rem;
      }
    }
  }
}
