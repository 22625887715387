.imgWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  height: 500px;
  img {
    height: 100%;
  }
}
