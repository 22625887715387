.bg_align {
  text-align: justify;
  letter-spacing: 2px;
  word-spacing: 3px;
  margin-right: 15%;
  margin-left: 15%;
  color: white;
}
.paragraph_align {
  margin-left: 20px;
  margin-right: 20px;
}
