@import 'src/assets/scss/styles';
.mainWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 28px 0px;
  padding-bottom: 14%;
  .contentWrap {
    max-width: 500px;
  }

  .inputsWrap {
    max-width: 500px;
  }
}
.messageColor {
  color: #ce84b8;
}
.logoutText {
  color: white;
  font-weight: 1rem !important;
}
.imageWidth {
  width: 120%;
}

@media (min-width: 244px) {
  .logout_description {
    margin-top: 0;
    font-size: 1.7rem;
  }
  .imageWidth {
    width: 77%;
  }
}
@media (min-width: 104px) {
  .logout_description {
    margin-top: 0;
    font-size: 1.4rem;
  }
  .imageWidth {
    width: 80%;
  }
}

@media (min-width: 768px) {
  .logout_description {
    margin-top: 0;
    font-size: 1.8rem;
    display: flex;
    justify-content: flex-start;
  }
}

@media (min-width: 992px) {
  .logout_description {
    color: white;
    margin-top: 0;
    font-size: 2rem;
    display: flex;
    justify-content: flex-start;
  }
}

@media (min-width: 1200px) {
  .logout_description {
    color: white;
    margin-top: 0;
    font-size: 2.3rem !important;
    display: flex;
    justify-content: flex-start;
  }
}
