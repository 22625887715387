@import 'src/assets/scss/styles';

.singleInput {
  display: flex;
}
.singleInput i {
  padding: 8px 16px;
  color: white;
}
.labelAlignment {
  color: white;
}
.customlables {
  font-size: 24px;
  color: #fff;
}
.labelAlignments {
  color: white;
  font-size: xx-large;
}
.labelAlignmentss {
  color: white;

  font-size: xx-large;
}
button {
  margin: 0%;
  padding: 0%;
}
.labelAlignmentDay {
  color: white;

  border: 3px solid #be70ac;
  font-size: x-large;
  width: 100%;
}
button .btn {
  margin: 0%;
}
.dropDownColor {
}
.spaceing {
  padding: 5%;
}
.btn:focus {
  outline: 0;
  box-shadow: none;
}
.settingsDropDownInput {
  background-color: transparent;
  border: none;
}
.marginMoveBlock {
  margin: 10px;
  color: white;
  margin-top: 20px;
  background-color: transparent;
}
.settingsDropDownInput:disabled {
  color: white;
  background-color: transparent !important;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none !important;
  font-size: 30px;
}
.settingsDropDownInput::placeholder {
  color: white;
  font-size: x-large;
}
.dropDownDay ::after {
  color: #be70ac;
  border-top: 1em solid;
  border-right: 0.9em solid transparent !important;
  border-bottom: 0;
  border-left: 0.9em solid transparent !important;
}

.dropDownMonth ::after {
  color: #be70ac;
  border-top: 1em solid;
  border-right: 0.9em solid transparent !important;
  border-bottom: 0;
  border-left: 0.9em solid transparent !important;
}
.dropDownColor ::after {
  color: #be70ac;
  border-top: 1.3em solid;
  border-right: 1.1em solid transparent !important;
  border-bottom: 0;
  border-left: 1.1em solid transparent !important;
}
.paddingLeft {
  padding-left: 26%;
}
.socialLogin {
  width: 330px;
  display: flex;
  height: 41px;
  border-color: transparent;
}
.iconSize {
  width: 28.28px;
  height: 34.52px;
}
.iconPaymentSize {
  width: 34.31px;
  height: 32.52px;
}
.settingsMargins {
  color: white !important;
  margin-bottom: 0px;
  background-color: transparent !important;
}
.settingsMargins:focus {
  background-color: transparent !important;
}
.singleInput input {
  border: 0px solid #c1c1c1;
  width: 100%;
  outline: none;
  height: 38px;
  font-size: 30px;
}
::placeholder {
  color: white;
  opacity: 1;
}
.hrWidth {
  width: 825px;
  color: #fc00eb;
}
.margins {
  color: white;
  margin-right: 15px;
  background-color: transparent;
}
.dropDownDaysAfter {
  padding-left: 5000px;
}
.mainWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .contentWrap {
    max-width: 500px;
  }

  .inputsWrap {
    max-width: 500px;
  }
}
.singleButton {
  height: 38px;
  border: 2px solid #c1c1c1;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  height: 63px;
}
.formcheckinput {
  width: 2em;
  margin-left: -2.5em;
  background-image: url('../../assets/images/handle.png') !important;
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
  font-size: 30px !important;
  height: 1em !important;
}
.formcheckinput:checked {
  //    background-color: #BE70AC;
  //    border-color: #BE70AC;
  //   background-size: 75%;
  //    background-image: url('../../assets/images/afterhover.png') !important;
  background-color: white;
  border-color: white;
}
.formcheckinput:focus {
  border-color: white;
  outline: 0;
  box-shadow: none;
}
.formCheck {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.formSwitch {
  padding-left: 2.5em;
}
.formCheckinput {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}
.formSwitch .formCheckinput {
  width: 2em;
  margin-left: -2.5em;
  background-image: url('../../assets/images/handle.png');
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
.formCheck {
  padding-top: 1.6rem !important;
  padding-left: 7.5em !important;
}
.checkForm {
}
.day {
  border: 2px solid #be70ac;
  width: 109px;
  height: 64px;
}
// .form-switch .formcheckinput {
//     width: 2em;
//     margin-left: -2.5em;
//     background-image: url('../../assets/images/handle.png');
//     background-position: left center;
//     border-radius: 2em;
//     transition: background-position 0.15s ease-in-out;
// }
.settingbox {
  max-width: 700px;
  margin: auto;
  border: 1px solid #602871;
  border-radius: 10px;
  padding: 70px 20px;
}
.settinginnerboxs {
  padding: 20px;
  border-bottom: 1px solid rgb(252, 0, 235, 0.56);
  cursor: pointer;
}

@media (min-width: 1200px) {
  .settingsP {
    font-size: 1.7rem !important;
  }
}
@media (max-width: 1200px) {
  .singleInput input,
  .labelAlignment {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .settingbox {
    padding: 50px 10px;
    margin-bottom: 120px;
  }
  .formcheckinput {
    font-size: 14px !important;
    height: 1em !important;
    margin-top: 5px !important;
  }
  .settinginnerboxs {
    padding: 20px 0px;
  }
  .margins {
    margin-right: 6px;
  }
  .customlables {
    font-size: 16px;
  }
}

.selectedItem {
  color: #fff;
  background-color: #954c8a;
}
