@import 'src/assets/scss/styles';
$breakpoint-tablet: 768px;
.notifactionrow {
  border-bottom: 1px solid #fc00eb;
  margin-bottom: 10px;
}

.notifactionrow p {
  margin-bottom: 10px;
  font-size: 18px;
}
.blockmessageheading {
  color: #fff;
  font-size: 28px;
}
.usericon {
  width: 42px;
  height: 42px;
}
@media (max-width: $breakpoint-tablet) {
  .blockmessageheading {
    font-size: 20px;
  }

  .blockmessageheading p {
    font-size: 16px;
  }
}

.noMessage {
  border: 1px solid #ce84b8;
}

.resetBtn {
  margin-top: 10px;

  button {
    border: none;
    -webkit-appearance: none;
    background: #ffffff;
    height: 50px;
    width: 50px;
    border-radius: 50%;

    svg {
      color: #77357d;
      font-size: 2rem;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .resetBtn {
    button {
      height: 40px;
      width: 40px;

      svg {
        font-size: 1.7rem;
      }
    }
  }
}
