@import 'src/assets/scss/styles';
.notifactionrow {
  border-bottom: 1px solid #fc00eb;
  margin-bottom: 10px;
}
.notifactionrow p {
  margin-bottom: 10px;
  font-size: 18px;
}

.alertWrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nonotifaction {
  border: 1px solid #ce84b8;
}
