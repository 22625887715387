@import 'src/assets/scss/styles';

.singleInput {
  border: 1px solid #c1c1c1;
  display: flex;
  height: 61.06px;
}

.singleInput i {
  color: white;
}

.socialLogin {
  border: 1px solid #c1c1c1;
  display: flex;
  height: 41px;
  border-color: transparent;
}

.singleInput input {
  border: 0px solid #c1c1c1;
  width: 100%;
  outline: none;
  height: 37px;
  font-size: 18px;
}

::placeholder {
  color: white;
  opacity: 1;
}

.textArea {
  background-color: transparent !important;
  color: #fff !important;
  border: 1px solid #ced4da !important;
  box-shadow: none !important;
}
.messageView {
  color: white;
  margin-bottom: 15px;
}

.margins {
  margin: 4px;
  padding-top: 10px;
  color: white;
  padding-left: 14px;
  background-color: transparent;
}

.nameInput {
  width: 100%;

  &.hasValue {
    background: #ce84b8;
    margin: 8px 0 8px 5px;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    padding-right: 5px;
  }

  .nameText {
    white-space: nowrap;
    max-width: 145px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.hover {
  cursor: pointer;
  color: white;
}
.hover:hover {
  color: white;
}
.bgModal {
  color: #b4579c;
  background-color: #b4579c;
}
.formCheckInput {
  height: 25px;
  width: 25px;
  accent-color: #d369b7;
}
.fileslist {
  li {
    color: $white;
  }
}
.dragmessage {
  font-size: 15px !important;
}
.btn {
  border: transparent;
  background-color: transparent;
  height: 37px;
  width: 380px;
  color: white;
  text-align: left;
  padding-left: 15px;
  justify-content: space-between;
  align-items: center;

  &.dateText {
    font-size: 14px;
  }
}

.alignContent {
  display: flex;
  justify-content: flex-end !important;
  align-items: flex-end;
}
.mainWrapmessage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 28px 0px;
  margin: auto;
  max-width: 500px;

  .contentWrap {
    max-width: 500px;
  }

  .inputsWrap {
    max-width: 500px;
  }
}
.marginButtons {
  margin: 4px;
  padding-top: 3px;
  color: white;
  padding-left: 4px;
  background-color: transparent;
}
.singleButton {
  border: 2px solid #c1c1c1;
  display: flex;
  justify-content: center;
  margin-bottom: 4px;
  height: 42.26px;
  cursor: pointer;
}
.singleButtons {
  border: 2px solid #c1c1c1;
  display: flex;
  justify-content: center;
  height: 42.26px;
  cursor: pointer;
}
.messageColor {
  color: pink;
}
.marginPopup {
  margin: 4px;
  padding-top: 4px;
  color: white;
  padding-left: 14px;
  background-color: transparent;
}

@media (min-width: 244px) {
  .createMessageP {
    margin-top: 0;
    font-size: 0.9rem;
  }
}
@media (min-width: 104px) {
  .createMessageP {
    margin-top: 0;
    font-size: 0.2rem;
  }
}

@media (min-width: 768px) {
  .createMessageP {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1rem;
    display: flex;
    justify-content: flex-start;
  }
}

@media (min-width: 992px) {
  .createMessageP {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1rem;
    display: flex;
    justify-content: flex-start;
  }
}

@media (min-width: 1200px) {
  .createMessageP {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.3rem !important;
    display: flex;
    justify-content: flex-start;
  }
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: transparent;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.limitWarning {
  color: #fff;
  font-size: 13px;
}

.greyImg {
  width: 200px;
  height: 200px;
  background: #c5c5c5;
}

.progressWrap {
  min-width: 80px;
  display: flex;
  justify-content: flex-end;

  .progressBar {
    height: 0.5rem;
    width: 100%;
  }

  .trashIcon {
    cursor: pointer;
    margin-left: 2px;
  }
}

.fileName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 768px) {
  .mainWrap {
    margin-bottom: 120px;
    height: auto;
  }
  .singleButton,
  .singleButtons {
    margin: auto;
  }
  .mainWrapmessage {
    padding-bottom: 16% !important;
  }
}
