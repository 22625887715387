@import 'src/assets/scss/styles';
$white: #ffffff;
$breakpoint-tablet: 768px;
.paymenttable {
  border: 1px solid #ce84b8;
  color: $white;
}

.paymenttable {
  th {
    min-width: 110px;
    max-width: 110px;
    width: 110px;

    h4 {
      margin-bottom: 0px;
    }
  }

  tbody {
    border-top: 0px !important;

    tr {
      td {
        h5 {
          margin-bottom: 0px;
        }
      }
    }
  }
}
.paymenttitle {
  border-bottom: 0px;
  padding-top: 25px !important;
  padding-bottom: 25px !important;
  background: #ffffff !important;
  color: #793e76;
}
.pricebox {
  background: #ffffff !important;
  color: #793e76;
}
.paymentsplanbutton {
  background: #fff;
  border: none;
  -webkit-appearance: none;
  padding: 8px 10px;
  color: #793e76;
  font-size: 15px;
  font-weight: bold;
}
.paymentsplanbutton:hover {
  background: #793e76;
  color: #ffffff;
}

.paymentsplanbutton.current {
  background: #793e76;
  color: #ffffff;
}
@media (max-width: $breakpoint-tablet) {
  .tableresponsive {
    margin-bottom: 40px;
  }
}
