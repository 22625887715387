@import 'src/assets/scss/styles';

@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

.loginLogo {
  @include position(absolute, $top: 0, $left: 0, $right: 0, $bottom: 0);
  justify-content: center !important;
  display: flex;
}

.singleInput {
  border: 1px solid #c1c1c1;
  display: flex;
  margin-bottom: 15px;
}

.singleInput i {
  color: white;
}

.imgAlign {
  position: absolute !important;
  width: 40px !important;
  height: 39px !important;
}

.socialLogin {
  border: 1px solid transparent;
  display: flex;
  height: 41px;
}

.singleInput input {
  border: 0 solid #c1c1c1;
  width: 100%;
  outline: none;
  height: 30px;
  font-size: 18px;
}

::placeholder {
  color: white;
  opacity: 1; /* Firefox */
}

.positionAtEnd {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: flex-end !important;
}

.margins {
  margin: 10px;
  color: white;
  background-color: transparent;
}

.socialMargins {
  margin: 1px;
  width: 39px;
  color: white;
  background-color: blue;
}

.btn {
  border: transparent;
  background-color: transparent;
  height: 55px;
  width: 380px;
  color: white;
  font-size: 1.1em;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    margin-left: 10px;
  }
}

.socialBtn {
  border: transparent;
  background-color: lightpink;
  height: 39px;
  width: 592px;
  color: white;
  font-size: 1.0em;
  padding-left: 50px;
}

.socialPosition {
  position: absolute;
  width: 40px;
  height: 39px;
}

.alignContent {
  display: flex;
  justify-content: flex-end !important;
  align-items: flex-end;
}

.mainWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-top: 9%;
  .contentWrap {
    max-width: 500px;
  }

  .inputsWrap {
    max-width: 500px;
  }
}

@media (min-width: 244px) {
  .loginP {
    margin-top: 0;
    font-size: 0.9rem;
  }
}

@media (min-width: 768px) {
  .loginP {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1rem;
  }
}

@media (min-width: 992px) {
  .loginP {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1rem;
  }
}

@media (min-width: 1200px) {
  .loginP {
    font-size: 1.1rem;
  }
}
