@import 'src/assets/scss/styles';
$breakpoint-tablet: 768px;
$breakpoint-mobile: 480px;
.homemessagerow {
  border-bottom: 1px solid #fc00eb;
  margin-bottom: 30px !important;
}

.homemessagerow p {
  margin-bottom: 10px;
  font-size: 18px;
}

.homemessageheading {
  color: #fff;
  font-size: 25px;
}
.messageeditdelete {
  button {
    background: none;
    border: none;
    -webkit-appearance: none;
    svg {
      font-size: 20px;
      color: #fff;
    }
  }
  .edit {
    margin-right: 15px;
  }
}
.addmoreeditmessage {
  margin-top: 10px;

  button {
    border: none;
    -webkit-appearance: none;
    background: #ffffff;
    height: 80px;
    width: 80px;
    border-radius: 50%;

    svg {
      color: #77357d;
      font-size: 3.5rem;
    }
  }
}
.usericon {
  width: 42px;
  height: 42px;
}

@media (max-width: $breakpoint-tablet) {
  .homemessageheading {
    font-size: 20px;
  }

  .homemessagerow p {
    font-size: 13px;
  }
}
@media (max-width: $breakpoint-mobile) {
  .edittrash {
    max-width: 17px;
  }
  .usericon {
    margin-right: 1em !important;
  }
  .addmoreeditmessage {
    button {
      height: 40px;
      width: 40px;

      svg {
        font-size: 1.7rem;
      }
    }
  }
}

.noMessage {
  border: 1px solid #ce84b8;
}
