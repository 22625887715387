@import 'bootstrap/dist/css/bootstrap.min.css';
$website-height: 100%;
$breakpoint-tablet: 768px;
$breakpoint-mobile: 480px;
$main-heading: 60px;
$main-heading-color: #ce84b8;
$breakpoint-height-sidebar: 600px;

@mixin background($imgpath, $position: 0 0, $repeat: no-repeat, $background-size: cover) {
  background: {
    image: url($imgpath);
    position: $position;
    repeat: $repeat;
    size: $background-size;
  }
}

@mixin opacity($value: 0.5) {
  $ie-value: $value * 100;
  opacity: $value;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=' + $ie-value + ')';
  filter: alpha(opacity=$ie-value);
}

html {
  height: 100%;
}
$white: #ffffff;
$black: #000000;

$full-width: 100%;
$home-logo-height: 100vh;

html {
  height: $website-height;
}

@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

.react-datepicker {
  background: #77357d !important;
  position: absolute !important;
  display: flex !important;
  border: 1px solid #ff7aed !important;
}
.react-datepicker,
.react-datepicker__header {
  background: #77357d !important;
  border-bottom: 1px solid #ff7aed !important;
}
.react-datepicker__day-names,
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: $white !important;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #ff7aed !important;
  text-transform: uppercase !important;
  font-weight: normal !important;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: #ccc;
  border-style: solid;
  border-width: 1px 1px 0 0 !important;
  content: '';
  display: block;
  height: 13px !important;
  position: absolute;
  top: 9px;
  width: 13px !important;
  font-weight: normal !important;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 50% !important;
  background-color: #ff7aed !important;
  color: #fff;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 50% !important;
  background-color: #ff7aed !important;
}
.react-datepicker__day--outside-month {
  color: #707070 !important;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 50% !important;
  background-color: #ff7aed !important;
}

.react-datepicker__time-list {
  background: #77357d !important;
}

.react-datepicker__time-list-item {
  color: white;
  border: 1px solid white;
  border-radius: 20px;
  margin-top: 5px;
}

.react-datepicker__time-list-item--selected {
  background-color: #ce84b8 !important;
  color: white;
  font-weight: bold;
}

.react-datepicker__time-list-item:hover {
  background-color: #ce84b8 !important;
}

.react-datepicker__triangle::after {
  display: none;
}

.react-datepicker__triangle::before {
  display: none;
}

.react-datepicker__time-container {
  border-left: none !important;
}

.react-datepicker__time-box {
  padding: 10px 10px 0 0 !important;
  background: #77357d !important;
}

.react-datepicker__header--time {
  min-height: 64px !important;
}

.react-datepicker__time-list {
  height: 180px !important;
}

.react-datepicker__day--disabled {
  @include opacity(0.4);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: gray;
}

body {
  height: $website-height;
  background-color: #954c8a;
  background-image: linear-gradient(45deg, #954c8a 0%, #562260 30%, #80447b 66%, #cd82ad 100%);

  @include position(relative);
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-height: 100vh;
  background-position: center 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  z-index: 0;
}
.login-box,
.inner-box {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin: auto;
}

.homelogo {
  @include position(absolute, $top: 0, $left: 0, $right: 0, $bottom: 0);
  pointer-events: none;
}
#root {
  @include position(relative);
  min-height: $website-height;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}
#root:before {
  @include position(fixed, $top: 0, $left: 0);
  @include background('./../../assets/images/top-bg.png');
  content: '';
  width: 810px;
  height: 510px;
  z-index: -1;
}

#root:after {
  @include position(fixed, $bottom: 0, $right: 0);
  @include background('./../../assets/images/bottom-bg.png');
  content: '';
  width: 735px;
  height: 470px;
  z-index: -1;
}
.inner-container {
  padding-left: 150px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-width: 70%;
  margin: auto;
  width: 100%;
}
.create-message-inner-container {
  min-height: initial;
  margin: 0px auto;
}
.bottom-last {
  @include position(fixed, $bottom: 0);
  z-index: -1;
}

.bottom-center {
  @include position(fixed, $bottom: 248px);
  z-index: -1;
}

.top-bottom {
  @include position(fixed, $bottom: 360px);
  z-index: -1;
}

.navigation {
  width: $full-width;
  z-index: 22;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.navigation .navbar {
  margin-right: 5.5rem;
}

.inner-logo-2 {
  @include position(absolute, $top: 17.5%, $left: 13%);

  img {
    max-width: 90px;
  }
  display: none;
}
.page-heading {
  font-size: $main-heading;
  color: $main-heading-color;
}

.message-block {
  p {
    color: #fff;
  }
}
.custom-input-drop {
  flex-wrap: initial;

  button.dropdown-toggle.btn {
    border: 1px solid #be70ac;
    margin-inline: 10px;
    border-radius: 0px;
    color: #fff;
    box-shadow: none !important;
  }

  .dropdown-toggle.btn:after {
    color: #be70ac;
  }
}
@media (max-width: $breakpoint-tablet) {
  #root:before {
    width: $full-width;
    height: 200px;
  }

  .inner-logo-2 {
    display: block;
  }
  #root:after {
    width: $full-width;
    height: 300px;
  }
  .inner-logo-2 {
    @include position(relative);
    text-align: center;
    top: 0;
    left: initial;
  }
  .navigation .navbar {
    margin-right: 0rem;
  }
  .navigation .navbar-nav {
    background: #5f2872;
    position: absolute;
    max-width: 90%;
    left: 0;
    right: 0;
    margin: auto;
    box-shadow: -45px 0px 20px -45px #000000, 45px 0px 20px -45px #000000;
  }
  .navigation .navbar-nav:before {
    position: absolute;
    content: '';
    z-index: -1;
    bottom: 35px;
    left: 8px;
    top: 80%;
    width: 50%;
    background: #222;
    -webkit-box-shadow: 0 18px 15px #222;
    -moz-box-shadow: 0 18px 15px #222;
    box-shadow: 0 18px 15px #222;
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

  .navigation .navbar-nav:after {
    position: absolute;
    content: '';
    z-index: -1;
    bottom: 35px;
    right: 8px;
    top: 80%;
    width: 50%;
    background: #222;
    -webkit-box-shadow: 0 18px 15px #222;
    -moz-box-shadow: 0 18px 15px #222;
    box-shadow: 0 18px 15px #222;
    -webkit-transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  .inner-container {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;
  }
  .inner-box {
    margin: 15px auto;
  }
  .custom-input-drop {
    button.dropdown-toggle.btn {
      padding: 5px;
      font-size: 12px;
    }
  }
}

@media (max-height: $breakpoint-height-sidebar) {
  .inner-container {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;
  }
}
@media (max-width: $breakpoint-mobile) {
  .page-heading {
    font-size: 40px;
  }
}

.loadingSpinner {
  position: absolute;
  width: 100%;
  top: 49%;
  left: 4%;

  div {
    width: 3rem;
    height: 3rem;
  }
}

.P0 {
  padding: 0 !important;
}

.disabled {
  pointer-events: none;
  @include opacity(0.4);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: gray;
}

.animPlaceholderOpacity4 {
  @include opacity(0.4);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: gray;
}

.dropdown-toggle {
  background: transparent;
  color: #fff;
}

.dropdown-toggle:hover {
  background: transparent;
  color: #fff;
}

.dropdown-toggle:focus {
  background: transparent;
  color: #fff;
}

.dropdown-toggle:active {
  background: transparent;
  color: #fff;
}

.dropdownMenu {
  max-height: 260px !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  width: 70px !important;
  min-width: 70px !important;
  max-width: 70px !important;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 1s linear infinite;
  -moz-animation: rotating 1s linear infinite;
  -ms-animation: rotating 1s linear infinite;
  -o-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;
}

.toast-header {
  small {
    margin-left: 5px;
  }

  button.btn-close {
    margin-left: auto;
  }
}
